import React from "react";

const FullScreenContactSection = () => {
  return (
    <div style={styles.section}>
      <div style={styles.overlay}>
        <div className="content" style={styles.content}>
          <h2 style={styles.heading}>Need Assistance? We're Here to Help! 💬</h2>
          <p style={styles.message}>
            Our team is available to assist you with any questions you may have.
            Call us directly or send an email for personalized support!
          </p>
          <div style={styles.buttonsContainer}>
            {/* Call Button */}
            <button
              style={{ ...styles.button, ...styles.callButton }}
              onClick={() => (window.location.href = "tel:8828615492")}
            >
              📞 Call Us Now
            </button>
            {/* Mail Button */}
            <button
              style={{ ...styles.button, ...styles.mailButton }}
              onClick={() => (window.location.href = "mailto:calidtechllp@gmail.com")}
            >
              ✉️ Send Us an Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Inline Styles
const styles = {
  section: {
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundColor: "#2196F3",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    boxSizing: "border-box",
    padding: "20px",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    maxWidth: "800px",
    width: "90%",
    padding: "30px",
    backgroundColor: "#fff",
    borderRadius: "15px",
    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    animation: "fadeIn 0.8s ease-in-out",
  },
  heading: {
    fontSize: "32px",
    marginBottom: "15px",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    color: "#333",
    fontWeight: "bold",
  },
  message: {
    fontSize: "18px",
    marginBottom: "30px",
    color: "#555",
    fontStyle: "italic",
    lineHeight: "1.5",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginBottom: "30px",
  },
  button: {
    padding: "15px 25px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    transition: "transform 0.3s, box-shadow 0.3s",
  },
  callButton: {
    backgroundColor: "#4CAF50",
    color: "#fff",
  },
  mailButton: {
    backgroundColor: "#FF5722",
    color: "#fff",
  },
};

// Responsive Styles
const globalStyles = `
  .content {
    // margin-top: 200px; /* Default for desktop and tablets */
  }

  @media (max-width: 768px) {
    .content {
      margin-top: 00px; /* Mobile view */
    }
  }
`;

// Inject global styles for responsive media queries
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);

export default FullScreenContactSection;
