// ManufacturingAnybusSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./ManufacturingAnybusSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import ABC4011 from "../../../assets/pdf/Anybus-EtherNet-ABC4011.pdf";
import ABC3013 from "../../../assets/pdf/Anybus-PROFINET-ABC3013.pdf";
import ABC3028 from "../../../assets/pdf/Anybus-Modbus-TCP-ABC3028.pdf";
import ABC4023 from "../../../assets/pdf/Anybus-DP-device-ABC4023.pdf";
import ABC4090 from "../../../assets/pdf/Anybus-Common-Ethernet-ABC4090.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";

const ManufacturingAnybusSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Protocol converters",
  // };

  const productDataList1 = [
    //Anybus EtherNet - ABC4011
    {
      images: [
        require("../../../assets/productImages/ABC4011-EtherNetIP-Modbus-Tcp.png"),
        require("../../../assets/productImages/ABC4011-EtherNetIP-Modbus-Tcp-2.png"),
        require("../../../assets/productImages/ABC4011-EtherNetIP-Modbus-Tcp.png"),
        require("../../../assets/productImages/ABC4011-EtherNetIP-Modbus-Tcp-2.png"),
      ],
      title: "Anybus - ABC4011",
      about: (
        <>
          <p>
            The Anybus Communicator EtherNet/IP adapter - Modbus TCP server is
            an industrial protocol gateway that allows you to seamlessly
            transfer data between PLC control systems over EtherNet/IP.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Anybus - ABC4011 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore, Vapi, Halol, Nimrana
            . Contact us to avail best
            prices and discount for Anybus - ABC4011.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Protocol conversion between PROFIBUS and PROFINET</li>
          <li>Supports PROFINET IO device</li>
        </>
      ),
      downloadLink: ABC4011,
      hyperlinks: [],
    },

    //Anybus PROFINET - ABC3013
    {
      images: [
        require("../../../assets/productImages/Anybus-abc3013-profinet.png"),
        require("../../../assets/productImages/Anybus-abc3013-profinet-2.png"),
        require("../../../assets/productImages/Anybus-abc3013-profinet.png"),
        require("../../../assets/productImages/Anybus-abc3013-profinet-2.png"),
      ],
      title: "Anybus - ABC3013",
      about: (
        <>
          <p>
            Integrate your serial RS-232/485 based industrial devices and
            equipment to PROFINET control systems without any changes to the
            device. Just connect, configure and you're done.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Anybus - ABC3013 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore. Contact us to avail best
            prices and discount for Anybus - ABC3013.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Web-based drag and drop configuration interface</li>
          <li>Transfer up to 1 500 bytes of data in both directions</li>
        </>
      ),
      downloadLink: ABC3013,
      hyperlinks: [],
    },

    //Anybus Modbus TCP - ABC3028
    {
      images: [
        require("../../../assets/productImages/Anybus-Modbus TCP-ABC3028.png"),
        require("../../../assets/productImages/Anybus-Modbus TCP-ABC3028-2.png"),
        require("../../../assets/productImages/Anybus-Modbus TCP-ABC3028.png"),
        require("../../../assets/productImages/Anybus-Modbus TCP-ABC3028-2.png"),
      ],
      title: "Anybus - ABC3028",
      about: (
        <>
          <p>
            Integrate your Modbus RTU based industrial devices and equipment to
            Modbus TCP control systems without any changes to the device. Just
            connect, configure and you're done.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Anybus - ABC3028 in Pune, Aurangabad, Nasik, Ahmednagar, Taloja, Shedung, Rabale, Kolhapur,Vapi, Halol, Vadodara, Bhavnagar, Surat,Bhilwara, Solan,Sambhalpur, Hosur, Belgaum. Contact us to avail best
            prices and discount for Anybus - ABC3028.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Web-based drag and drop configuration interface</li>
          <li>Transfer up to 1 500 bytes of data in both directions </li>
        </>
      ),
      downloadLink: ABC3028,
      hyperlinks: [],
    },

    //Anybus Common Ethernet - ABC4090
    {
      images: [
        require("../../../assets/productImages/Anybus-Common-Ethernet-ABC4090.png"),
        require("../../../assets/productImages/Anybus-Common-Ethernet-ABC4090-2.png"),
        require("../../../assets/productImages/Anybus-Common-Ethernet-ABC4090.png"),
        require("../../../assets/productImages/Anybus-Common-Ethernet-ABC4090-2.png"),
      ],
      title: "Anybus - ABC4090",
      about: (
        <>
          <p>
            The Anybus Communicator Common Ethernet - Common Ethernet is an
            industrial protocol gateway that allows you to seamlessly transfer
            data between PLC control systems over EtherNet/IP, PROFINET
            networks.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Anybus - ABC4090 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore, Vapi, Halol, Nimrana
            . Contact us to avail best
            prices and discount for Anybus - ABC4090.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Instant data transfer</li>
          <li>Hardware-accelerated endian conversion (byte swap)</li>
        </>
      ),
      downloadLink: ABC4090,
      hyperlinks: [],
    },

    //Anybus DP device - ABC4023
    {
      images: [
        require("../../../assets/productImages/Anybus-DP-device-ABC4023.png"),
        require("../../../assets/productImages/Anybus-DP-device-ABC4023-2.png"),
        require("../../../assets/productImages/Anybus-DP-device-ABC4023.png"),
        require("../../../assets/productImages/Anybus-DP-device-ABC4023-2.png"),
      ],
      title: "Anybus - ABC4023",
      about: (
        <>
          <p>
            The Anybus Communicator PROFINET IO device - PROFIBUS DP device is
            an industrial protocol gateway that allows you to seamlessly
            transfer data between PLC control systems.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Anybus - ABC4023 in Pune, Aurangabad, Nasik, Ahmednagar, Taloja, Shedung, Rabale, Kolhapur,Vapi, Halol, Vadodara, Bhavnagar, Surat,Bhilwara, Solan,Sambhalpur, Hosur, Belgaum. Contact us to avail best
            prices and discount for Anybus - ABC4023.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Instant data transfer</li>
          <li>Hardware-accelerated endian conversion (byte swap)</li>
        </>
      ),
      downloadLink: ABC4023,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="anybus-page">
      <Helmet>
        <title>Anybus - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Calid Technologies offers a range of Anybus protocol converters, including models like ABC4023, ABC4090, ABC3028, ABC3013, and ABC4011. Optimize your manufacturing processes with industry-leading communication solutions from Anybus" />
        <meta name="keywords" content="Calid Technologies Anybus products,Industrial Ethernet gateways,Anybus converter distributor,Industrial automation communication,Manufacturing network integration,Anybus product supplier,Protocol converters for manufacturing,Industrial communication solutions,Anybus ABC4011,Anybus ABC3013,Anybus ABC3028,Anybus ABC4090,Anybus ABC4023Anybus protocol converters,Anybus supplier in mumbai and pune" />
        <meta
          name="description"
          content="Calid Technologies offers a range of Anybus protocol converters, including models like ABC4023, ABC4090, ABC3028, ABC3013, and ABC4011. Optimize your manufacturing processes with industry-leading communication solutions from Anybus"
        />
        <meta
          name="keywords"
          content="Calid Technologies Anybus products,Industrial Ethernet gateways,Anybus converter distributor,Industrial automation communication,Manufacturing network integration,Anybus product supplier,Protocol converters for manufacturing,Industrial communication solutions,Anybus ABC4011,Anybus ABC3013,Anybus ABC3028,Anybus ABC4090,Anybus ABC4023Anybus protocol converters,Anybus supplier in mumbai and pune"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Anybus Dealer in Pune" />
        <meta name="keywords" content="Best Anybus Dealer in Pune" />
        <meta name="keywords" content="Anybus Distributor in Pune" />

        <meta name="keywords" content="Anybus Dealer in Mumbai" />
        <meta name="keywords" content="Best Anybus Dealer in Mumbai" />
        <meta name="keywords" content="Anybus Distributor in Mumbai" />

        <meta name="keywords" content="Anybus Dealer in Maharashtra" />
        <meta name="keywords" content="Best Anybus Dealer in Maharashtra" />
        <meta name="keywords" content="Anybus Distributor in Maharashtra" />

        <meta name="keywords" content="Anybus Dealer in Vadodara" />
        <meta name="keywords" content="Best Anybus Dealer in Vadodara" />
        <meta name="keywords" content="Anybus Distributor in Vadodara" />

        <meta name="keywords" content="Anybus Dealer in Surat" />
        <meta name="keywords" content="Best Anybus Dealer in Surat" />
        <meta name="keywords" content="Anybus Distributor in Surat" />

        <meta name="keywords" content="Anybus Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Anybus Dealer in Ahmedabad" />
        <meta name="keywords" content="Anybus Distributor in Ahmedabad" />

        <meta name="keywords" content="Anybus Dealer in Indore" />
        <meta name="keywords" content="Best Anybus Dealer in Indore" />
        <meta name="keywords" content="Anybus Distributor in Indore" />

        <meta name="keywords" content="Anybus Dealer in Jaipur" />
        <meta name="keywords" content="Best Anybus Dealer in Jaipur" />
        <meta name="keywords" content="Anybus Distributor in Jaipur" />

        <meta name="keywords" content="Anybus Dealer in Noida" />
        <meta name="keywords" content="Best Anybus Dealer in Noida" />
        <meta name="keywords" content="Anybus Distributor in Noida" />

        <meta name="keywords" content="Anybus Dealer in Gurugram" />
        <meta name="keywords" content="Best Anybus Dealer in Gurugram" />
        <meta name="keywords" content="Anybus Distributor in Gurugram" />

        <meta name="keywords" content="Anybus Dealer in Delhi" />
        <meta name="keywords" content="Best Anybus Dealer in Delhi" />
        <meta name="keywords" content="Anybus Distributor in Delhi" />

        <meta name="keywords" content="Anybus Dealer in Kolkata" />
        <meta name="keywords" content="Best Anybus Dealer in Kolkata" />
        <meta name="keywords" content="Anybus Distributor in Kolkata" />

        <meta name="keywords" content="Anybus Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Anybus Dealer in Bhubaneswar" />
        <meta name="keywords" content="Anybus Distributor in Bhubaneswar" />

        <meta name="keywords" content="Anybus Dealer in Hyderabad" />
        <meta name="keywords" content="Best Anybus Dealer in Hyderabad" />
        <meta name="keywords" content="Anybus Distributor in Hyderabad" />

        <meta name="keywords" content="Anybus Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Anybus Dealer in Visakhapatnam" />
        <meta name="keywords" content="Anybus Distributor in Visakhapatnam" />

        <meta name="keywords" content="Anybus Dealer in Chennai" />
        <meta name="keywords" content="Best Anybus Dealer in Chennai" />
        <meta name="keywords" content="Anybus Distributor in Chennai" />

        <meta name="keywords" content="Anybus Dealer in Coimbatore" />
        <meta name="keywords" content="Best Anybus Dealer in Coimbatore" />
        <meta name="keywords" content="Anybus Distributor in Coimbatore" />

        <meta name="keywords" content="Anybus Dealer in Bengaluru" />
        <meta name="keywords" content="Best Anybus Dealer in Bengaluru" />
        <meta name="keywords" content="Anybus Distributor in Bengaluru" />

        <meta name="keywords" content="Anybus Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Anybus Dealer in Tamil Nadu" />
        <meta name="keywords" content="Anybus Distributor in Tamil Nadu" />

        <meta name="keywords" content="Anybus Dealer in Karnataka" />
        <meta name="keywords" content="Best Anybus Dealer in Karnataka" />
        <meta name="keywords" content="Anybus Distributor in Karnataka" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Calid Technologies offers a range of Anybus protocol converters, including models like ABC4023, ABC4090, ABC3028, ABC3013, and ABC4011. Optimize your manufacturing processes with industry-leading communication solutions from Anybus"
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Calid Technologies - Anybus" />
        <meta
          name="twitter:description"
          content="Calid Technologies offers a range of Anybus protocol converters, including models like ABC4023, ABC4090, ABC3028, ABC3013, and ABC4011. Optimize your manufacturing processes with industry-leading communication solutions from Anybus"
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Anybus" />
        <meta
          itemprop="description"
          content="Calid Technologies offers a range of Anybus protocol converters, including models like ABC4023, ABC4090, ABC3028, ABC3013, and ABC4011. Optimize your manufacturing processes with industry-leading communication solutions from Anybus"
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://www.calidtech.com/ManufacturingAnybusSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Industrial Edge Connectivity - Anybus
      </div>
      <div className="anybus-subproduct-nav">
        <button
          className={`anybus-subproduct-nav-button ${currentSubproduct === 1 ? "active-anybus-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Protocol converters
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="anybus-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-anybus-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-anybus" : ""}
                >
                  <button
                    className="anybus-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <ContactButtons /> */}

      {/* <Whatsapp /> */}

      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingAnybusSubcategoryPage;
