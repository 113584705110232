import React, { useState } from "react";
import "./ContactButtons.css";

const ContactPopup = () => {
  const [isOptionsVisible, setOptionsVisible] = useState(false);

  const toggleOptions = () => {
    setOptionsVisible((prev) => !prev);
  };

  const makeCall = () => {
    window.location.href = "tel:+918828615492"; // Replace with your phone number
  };

  const openWhatsApp = () => {
    window.open("https://wa.me/8828615492", "_blank"); // Replace with your WhatsApp link
  };

  const sendMail = () => {
    window.location.href = "mailto:info@calidtech.com"; // Replace with your email
  };

  return (
    <div className="floating-container">
      {/* Main Floating Button */}
      <button className="floating-button" onClick={toggleOptions}>
        {/* Phone Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.86 19.86 0 0 1-8.63-3.29 19.42 19.42 0 0 1-6-6A19.86 19.86 0 0 1 2.1 4.18 2 2 0 0 1 4 2h3a2 2 0 0 1 2 1.72 12.4 12.4 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8 9a16 16 0 0 0 6 6l.36-.25a2 2 0 0 1 2.11-.45 12.4 12.4 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
        </svg>
      </button>

      {/* Options */}
      {isOptionsVisible && (
        <div className="floating-options">
          <button className="option-button" onClick={makeCall}>
            {/* Phone Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.86 19.86 0 0 1-8.63-3.29 19.42 19.42 0 0 1-6-6A19.86 19.86 0 0 1 2.1 4.18 2 2 0 0 1 4 2h3a2 2 0 0 1 2 1.72 12.4 12.4 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8 9a16 16 0 0 0 6 6l.36-.25a2 2 0 0 1 2.11-.45 12.4 12.4 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          </button>

          <button className="option-button" onClick={openWhatsApp}>
            {/* Correct WhatsApp Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="24"
              height="24"
              fill="currentColor"
            >
              <path d="M16 0C7.163 0 0 7.163 0 16c0 2.99.806 5.787 2.204 8.221L.203 32l7.881-1.995C10.226 31.194 13.052 32 16 32c8.837 0 16-7.163 16-16S24.837 0 16 0zm9.842 22.758c-.406 1.134-2.03 2.088-3.281 2.377-.872.198-1.992.358-6.49-1.378-5.463-2.148-8.975-7.487-9.247-7.835-.272-.349-2.207-2.935-2.207-5.595 0-2.66 1.366-3.978 1.847-4.523.481-.545 1.047-.681 1.396-.681.349 0 .698.003.997.018.324.016.757-.121 1.185.91.445 1.06 1.513 3.663 1.646 3.926.134.262.223.603.045.951-.178.349-.267.604-.534.923-.27.316-.47.56-.713.902-.214.299-.438.623-.19 1.043.248.419 1.109 1.843 2.378 2.985 1.633 1.455 3.017 1.91 3.436 2.128.419.218.662.195.906-.113.244-.309 1.045-1.221 1.326-1.638.272-.407.545-.34.91-.204.364.137 2.303 1.086 2.7 1.286.396.2.663.296.76.462.093.166.093 1.049-.313 2.182z" />
            </svg>
          </button>

          <button className="option-button" onClick={sendMail}>
            {/* Email Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2Z" />
              <polyline points="22,6 12,13 2,6" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default ContactPopup;
