import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./JobBoard.css";
import logowithoutbg from "./assets/images/logo_bgremove.png";
import Whatsapp from "./Common Components/Whatsapp";
import ContactUsBot from "./Common Components/ContactUsBot";
import JobPostingFooter from "./JobPages/JobComponents/JobPostingFooter";
import CallMePopup from "./Common Components/CallMePopup";
import ShowMoreIcons from "./Common Components/ShowMoreIcons";

const jobPostings = [
  {
    id: 1,
    title: "Technical Business Development Executive",
    type: "Permanent",
    location: "Mumbai",
    description:
      "Well versed with networking (both wired and wireless), switching and routing technologies, peripheral security, and information security practices. Good working connections with OEMs and end users across verticals. 5 to 7 years of work experience. Remuneration includes additional performance-based incentives.",
    applyLink: "/TechnicalBusinessDevelopmentExecutive",
  },
  {
    id: 2,
    title: "ICCC Sr. Associate",
    type: "Permanent",
    location: "Remote",
    description:
      "Looking for a Senior Operations Associate with 2+ years of experience in a technical call center or an integrated command center. Proficient with new-age technologies around smart cities. Proficiency in Excel and good communication skills. Not an end-user facing opportunity but more of an onsite team enabler and manager.",
    applyLink: "/ICCCJob",
  },
  {
    id: 3,
    title: "IoT Engineer",
    type: "N/A",
    location: "Pune",
    description:
      "Looking for an experienced IoT Engineer, specializing in Industrial Automation & IoT. The candidate should have at least 2 years of relevant experience and hold a B.Tech/B.E/Diploma in a related field. Must be proficient in Python, Javascript, or similar languages.",
    applyLink: "/IoTEngineer",
  },
];

const JobBoard = ({ siteName }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showTitle, setShowTitle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isTop = scrollTop < 100;
      setIsScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTitle(true);
    }, 300);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="">
      <div className={`small-header ${isScrolled ? "scrolled" : ""}`}>
        <p>Job Board</p>
      </div>

      <nav className={`custom-header-unique ${isScrolled ? "scrolled" : ""}`}>
        <input type="checkbox" id="nav-toggle-unique" />
        <div className="logo-unique">
          <img
            className="header-logo-withoutbg"
            src={logowithoutbg}
            alt="Logo"
          />
        </div>

        <div className="site-banner">{siteName}</div>
        <ul className="links-unique">
          <li>
            <a href="#home-unique">
              <Link className="a-link" to="/EnterpriselandingPage">
                Enterprise Solutions
              </Link>
            </a>
          </li>
          <li>
            <a href="#work-unique">
              <Link className="a-link" to="/ManufacturinglandingPage">
                Factory Digital Transformation Solutions
              </Link>
            </a>
          </li>
        </ul>

        <label htmlFor="nav-toggle-unique" className="icon-burger-unique">
          <div className="line-unique"></div>
          <div className="line-unique"></div>
          <div className="line-unique"></div>
        </label>
      </nav>

      <div className="JobBoardDiv">
        <div className="job-board-container">
          <h1 className={`job-title ${showTitle ? "fade-in" : ""}`}>
            Job Postings
          </h1>
          <div className="job-card-grid">
            {jobPostings.map((job) => (
              <div className="job-card" key={job.id}>
                <h2>{job.title}</h2>
                <p>{job.description}</p>
                <div className="apply-div">
                  <div className="job-details-div">
                    <div className="job-details">
                      <span>Type:</span>
                      <span>{job.type}</span>
                    </div>
                    <div className="job-details">
                      <span>Location:</span>
                      <span>{job.location}</span>
                    </div>
                  </div>
                  <Link to={job.applyLink}>
                    <button className="apply-button">Apply Now</button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <JobPostingFooter />
      {/* <Whatsapp /> */}

      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
    </div>
  );
};

export default JobBoard;
