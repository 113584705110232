import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EntCaseStudyCard from "../EntertrpriseComponents/EnterpriseCaseStudyCard";
import EnterpriseHeader from "../EntertrpriseComponents/EnterpriseHeader";
import SwitchtoManufacturing from "../EntertrpriseComponents/SwitchtoManufacturing";
import EnterpriseFooter from "../EntertrpriseComponents/EnterpriseFooter";
import Whatsapp from "../../Common Components/Whatsapp";
import ContactUsBot from "../../Common Components/ContactUsBot";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../Common Components/ContactButtons";
import CallMePopup from "../../Common Components/CallMePopup";
import ShowMoreIcons from "../../Common Components/ShowMoreIcons";


// Images
import Edelweiss from "../../assets/images/Edelweiss.jpg";
import ICICI from "../../assets/images/ICICI.jpg";
import Axxela from "../../assets/images/Axxela.jpg";
import Karkinos from "../../assets/images/Karkinos.jpg";

const EnterpriseCaseStudyPage = () => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoaded(true);
  }, []);

  const caseStudies = [
    {
      title: "ICICI Bank",
      description:
        "The core requirement of this project was to design, architect and deploy a stable and most importantly secure wireless network for the bank employees.",
      image: ICICI,
      link: "/IciciCaseStudyPage",
    },
    {
      title: "Axxela Research and Analytics",
      description:
        "How to establish the connectivity amongst HQ, RO and DC and Traders network",
      image: Axxela,
      link: "/AxxelaCaseStudyPage",
    },
    {
      title: "Karkinos Healthcare Pvt Ltd",
      description:
        "Study the requirement and design the access network architecture and access methods on the wired and wireless network",
      image: Karkinos,
      link: "/KarkinosCaseStudyPage",
    },
    {
      title: "Edelweiss Financials",
      description:
        "Edelweiss was rapidly growing and it felt a dire need to consolidate all its scattered efforts into one centralised entity that could govern and extend based on frequent requirements.",
      image: Edelweiss,
      link: "/EdelweissCaseStudyPage",
    },
  ];

  const handleCardClick = (link) => {
    navigate(link);
  };

  return (
    <div className="">
      <Helmet>
        <title>Calid Technologies Case Studies - HPE/Aruba, Palo Alto, Dell, Lenovo, Radmin, Altai Dealers Distributors in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Your website description here" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseCaseStudyPage" />
      </Helmet>
      {/* <Hero /> */}
      {/* <div
        className="ImageBackgroundWrap Background-fixed"
        style={{
          backgroundImage: `url(${CaseStudyIndividualPage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="ImageInnerWrap" id="CaseStudyPageInnerDiv"></div>
      </div> */}

      {/* case study page start */}
      <div className={`case-study-page ${loaded ? "loaded" : ""}`}>
        <div className="headline" id="CaseStudyDv">
          <h1>Case Studies</h1>
          <p>Explore our latest case studies</p>
        </div>
        <div className="case-study-cards">
          {caseStudies.map((study, index) => (
            <EntCaseStudyCard
              key={index}
              title={study.title}
              description={study.description}
              image={study.image}
              onClick={() => handleCardClick(study.link)}
            />
          ))}
        </div>
      </div>
      <EnterpriseHeader />
      {/* <Whatsapp /> */}

      <SwitchtoManufacturing />
      {/* <ContactButtons /> */}

      <EnterpriseFooter />
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
    </div>
  );
};

export default EnterpriseCaseStudyPage;
