// ManufacturingSmartIoT4GGatewaySubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingSmartIoT4GGatewaySubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import Dalnex from "../../../assets/pdf/Dalnex.pdf";
import EwonFlexy205 from "../../../assets/pdf/Ewon-Flexy-205.pdf";
import AIG101 from "../../../assets/pdf/AIG-101.pdf";
import AIG301 from "../../../assets/pdf/AIG-301.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";

const ManufacturingSmartIoT4GGatewaySubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Dalnex",
  //   2: "EWON HMS",
  //   3: "Moxa",
  // };

  const productDataList1 = [
    // Dalnex
    {
      images: [
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
        require("../../../assets/productImages/Smart-Dalnex-1.png"),
      ],
      title: "Dalnex",
      about: (
        <>
          <p>
            The Dalnex IOT gateway is able collect data from sensors/energy meters/water
            sensors and any field device which run on Modbus RTU (RS232/422/485)
            interface and transmit it to onprem/ cloud server.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Dalnex IOT gateway in Maharashtra, Surat, MP, Noida,
            Uttarakhand, Jharkhand, Cuttak, Belgaum. Contact us to avail best
            prices and discount for Dalnex IOT gateway.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Predictive Maintenance.</li>
          <li>Monitoring Machine Health.</li>
        </>
      ),
      downloadLink: Dalnex,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //Ewon Flexy 205
    {
      images: [
        require("../../../assets/productImages/Ewon-Flexy-205-1.jpg"),
        require("../../../assets/productImages/Ewon-Flexy-205-1.jpg"),
        require("../../../assets/productImages/Ewon-Flexy-205-1.jpg"),
        require("../../../assets/productImages/Ewon-Flexy-205-1.jpg"),
      ],
      title: "Ewon Flexy 205",
      about: (
        <>
          <p>
            The Ewon Flexy family is a range of industrial modular gateways that
            allows universal communication with varied field equipment
            regardless of the protocol used.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of Ewon Flexy 205 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore. Contact us to avail
            best prices and discount for Ewon Flexy 205.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Easy setup for unmatched connectivity success</li>
          <li>Local web-dashboard for remote monitoring</li>
        </>
      ),
      downloadLink: EwonFlexy205,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //AIG-101
    {
      images: [
        require("../../../assets/productImages/AIG-101-1.png"),
        require("../../../assets/productImages/AIG-101-2.png"),
        require("../../../assets/productImages/AIG-101-3.png"),
        require("../../../assets/productImages/AIG-101-4.png"),
      ],
      title: "AIG-101",
      about: (
        <>
          <p>
            The AIG-101 Series gateways are entry-level IIoT gateways that
            connect Modbus RTU/ASCII/TCP devices to cloud platforms and
            applications such as Azure, AWS, and MQTT.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of AIG-101 in Vadodara, Bhavnagar,Mumbai, Surat, Ahmedabad, Rajkot,Gurugram, patratu, Jamshedpur,Coimbatoor, Hosur, Salem, Bengaluru, Belgaum, Manglore, Vapi, Halol, Nimrana. Contact us to avail best
            prices and discount for AIG-101.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Supports generic MQTT client</li>
          <li>Supports Modbus TCP server</li>
        </>
      ),
      downloadLink: AIG101,
      hyperlinks: [],
    },

    //AIG-301
    {
      images: [
        require("../../../assets/productImages/AIG-301-1.png"),
        require("../../../assets/productImages/AIG-301-2.png"),
        require("../../../assets/productImages/AIG-301-3.png"),
        require("../../../assets/productImages/AIG-301-4.png"),
      ],
      title: "AIG-301",
      about: (
        <>
          <p>
            The AIG-301 Series advanced IIoT gateways are designed for
            Industrial IoT applications, especially for distributed and unmanned
            sites in harsh operating environments.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of AIG-301 in Maharashtra, Surat, MP, Noida,
            Uttarakhand, Jharkhand, Cuttak, Belgaum. Contact us to avail best
            prices and discount for AIG-301.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>Supports generic MQTT client</li>
          <li>Supports Modbus RTU/ASCII/TCP master/client</li>
        </>
      ),
      downloadLink: AIG301,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="smartIoT4GGateway-page">
      <Helmet>
        <title>Smart IoT 4G Gateway - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta
          name="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Smart IoT 4G Gateway Dealer in Pune" />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Pune"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Pune"
        />

        <meta name="keywords" content="Smart IoT 4G Gateway Dealer in Mumbai" />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Mumbai"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Mumbai"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Maharashtra"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Maharashtra"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Maharashtra"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Vadodara"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Vadodara"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Vadodara"
        />

        <meta name="keywords" content="Smart IoT 4G Gateway Dealer in Surat" />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Surat"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Surat"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Ahmedabad"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Ahmedabad"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Ahmedabad"
        />

        <meta name="keywords" content="Smart IoT 4G Gateway Dealer in Indore" />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Indore"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Indore"
        />

        <meta name="keywords" content="Smart IoT 4G Gateway Dealer in Jaipur" />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Jaipur"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Jaipur"
        />

        <meta name="keywords" content="Smart IoT 4G Gateway Dealer in Noida" />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Noida"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Noida"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Gurugram"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Gurugram"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Gurugram"
        />

        <meta name="keywords" content="Smart IoT 4G Gateway Dealer in Delhi" />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Delhi"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Delhi"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Kolkata"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Kolkata"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Kolkata"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Bhubaneswar"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Bhubaneswar"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Bhubaneswar"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Hyderabad"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Hyderabad"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Hyderabad"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Visakhapatnam"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Chennai"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Chennai"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Chennai"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Coimbatore"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Coimbatore"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Coimbatore"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Bengaluru"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Bengaluru"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Bengaluru"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Tamil Nadu"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Tamil Nadu"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Tamil Nadu"
        />

        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Dealer in Karnataka"
        />
        <meta
          name="keywords"
          content="Best Smart IoT 4G Gateway Dealer in Karnataka"
        />
        <meta
          name="keywords"
          content="Smart IoT 4G Gateway Distributor in Karnataka"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta
          name="twitter:description"
          content="Discover how Calid Technologies delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring."
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://calidtech.com/ManufacturingSmartIoT4GGatewaySubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Communication And Computing - Smart IoT 4G Gateway
      </div>
      <div className="smartIoT4GGateway-subproduct-nav">
        <button
          className={`smartIoT4GGateway-subproduct-nav-button ${currentSubproduct === 1 ? "active-smartIoT4GGateway-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Dalnex
        </button>
        <button
          className={`smartIoT4GGateway-subproduct-nav-button ${currentSubproduct === 2 ? "active-smartIoT4GGateway-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          EWON HMS
        </button>
        <button
          className={`smartIoT4GGateway-subproduct-nav-button ${currentSubproduct === 3 ? "active-smartIoT4GGateway-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(3)}
        >
          Moxa
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="smartIoT4GGateway-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-smartIoT4GGateway-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-smartIoT4GGateway" : ""
                  }
                >
                  <button
                    className="smartIoT4GGateway-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <Whatsapp /> */}

      {/* <ContactButtons /> */}

      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingSmartIoT4GGatewaySubcategoryPage;
