import React, { useState, useEffect } from "react";

const CallPopup = () => {
    const [showTab, setShowTab] = useState(false);

    useEffect(() => {
        // Show tab immediately on first load
        setShowTab(true);

        // Tab visibility logic
        const tabInterval = setInterval(() => {
            setShowTab(true);
            setTimeout(() => setShowTab(false), 2000); // Tab disappears after 2 seconds
        }, 4000); // Show every 4 seconds (2s on, 2s off)

        return () => clearInterval(tabInterval);
    }, []);

    return (
        <>
            <style>
                {`
                .call-popup {
                    position: fixed;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%); /* Center vertically */
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    z-index: 1000;
                    transition: all 0.3s ease-in-out;
                }

                .call-icon-container {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    background-color: #007bff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    animation: phone-ring 1.5s infinite;
                }

                @keyframes phone-ring {
                    0% { transform: rotate(0deg); }
                    25% { transform: rotate(-15deg); }
                    50% { transform: rotate(15deg); }
                    75% { transform: rotate(-15deg); }
                    100% { transform: rotate(0deg); }
                }

                .call-icon {
                    font-size: 24px;
                    color: white;
                }

                .tab {
                    position: absolute;
                    top: 50%;
                    right: 100%; /* Position the tab to the left of the icon */
                    transform: translateY(-50%);
                    background-color: white;
                    color: black;
                    padding: 10px 15px;
                    border-radius: 10px;
                    font-size: 14px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    white-space: nowrap;
                    z-index: 1001; /* Ensure tab is above everything */
                    visibility: hidden; /* Initially hidden */
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    border: 3px solid transparent; /* Initial border transparent */
                    background-clip: padding-box;
                    animation: glowing-border 2s infinite alternate; /* Neon glowing border animation */
                }

                @keyframes glowing-border {
                    0% {
                        border-color: #00f7ff; /* Neon blue */
                        box-shadow: 0 0 10px #00f7ff, 0 0 20px #00f7ff, 0 0 30px #00f7ff;
                    }
                    50% {
                        border-color: #1e90ff; /* Lighter blue */
                        box-shadow: 0 0 10px #1e90ff, 0 0 20px #1e90ff, 0 0 30px #1e90ff;
                    }
                    100% {
                        border-color: #007bff; /* Darker blue */
                        box-shadow: 0 0 10px #007bff, 0 0 20px #007bff, 0 0 30px #007bff;
                    }
                }

                .tab.show {
                    visibility: visible;
                    opacity: 1; /* Make the tab visible */
                    right: 70px; /* Adjust the position to show next to the icon */
                }

                .tab.hidden {
                    visibility: hidden; /* Hide the tab */
                    opacity: 0;
                    right: 100%; /* Move the tab off-screen */
                }
                `}
            </style>
            <div className="call-popup">
                <a
                    href="tel:7977584476" // Replace with your phone number
                    className="call-icon-container"
                >
                    <div className="call-icon">📞</div>
                </a>
                <div className={`tab ${showTab ? "show" : "hidden"}`}>
                    Get immediate assistance
                </div>
            </div>
        </>
    );
};

export default CallPopup;
