import React, { useState } from "react";
import "./ShowMoreIcons.css";

const ShowMoreIcons = () => {
    const [showIcons, setShowIcons] = useState(false);
    const [isRotated, setIsRotated] = useState(false);

    const toggleIcons = () => {
        setShowIcons(!showIcons);
        setIsRotated(!isRotated); // Toggle rotation state
    };


    return (
        <div className="icon-container">
            <div
                className={`plus-icon ${isRotated ? "rotate" : ""}`}
                onClick={toggleIcons}
            >
                +
            </div>
            <div className={`icons ${showIcons ? "show" : ""}`}>
                <a
                    href="https://wa.me/8828615492"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-icon"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#e0e0e0" viewBox="0 0 256 256"><path d="M187.58,144.84l-32-16a8,8,0,0,0-8,.5l-14.69,9.8a40.55,40.55,0,0,1-16-16l9.8-14.69a8,8,0,0,0,.5-8l-16-32A8,8,0,0,0,104,64a40,40,0,0,0-40,40,88.1,88.1,0,0,0,88,88,40,40,0,0,0,40-40A8,8,0,0,0,187.58,144.84ZM152,176a72.08,72.08,0,0,1-72-72A24,24,0,0,1,99.29,80.46l11.48,23L101,118a8,8,0,0,0-.73,7.51,56.47,56.47,0,0,0,30.15,30.15A8,8,0,0,0,138,155l14.61-9.74,23,11.48A24,24,0,0,1,152,176ZM128,24A104,104,0,0,0,36.18,176.88L24.83,210.93a16,16,0,0,0,20.24,20.24l34.05-11.35A104,104,0,1,0,128,24Zm0,192a87.87,87.87,0,0,1-44.06-11.81,8,8,0,0,0-6.54-.67L40,216,52.47,178.6a8,8,0,0,0-.66-6.54A88,88,0,1,1,128,216Z"></path></svg>                </a>
                <a
                    href="mailto:info@calidtech.com"
                    className="mail-icon icon-border"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#38a3fd" viewBox="0 0 256 256"><path d="M228.44,89.34l-96-64a8,8,0,0,0-8.88,0l-96,64A8,8,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A8,8,0,0,0,228.44,89.34ZM128,41.61l81.91,54.61-67,47.78H113.11l-67-47.78ZM40,200V111.53l65.9,47a8,8,0,0,0,4.65,1.49h34.9a8,8,0,0,0,4.65-1.49l65.9-47V200Z"></path></svg>
                </a>
                <a
                    href="https://www.instagram.com/calid_technologies/"
                    target="_blank"
                    className="insta-icon icon-border"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#38a3fd" viewBox="0 0 256 256"><path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160ZM176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24Zm40,152a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V80A40,40,0,0,1,80,40h96a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z"></path></svg>                </a>
                <a
                    href="https://www.linkedin.com/company/calid-technologies/"
                    target="_blank"
                    className="linkedin-icon icon-border"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#38a3fd" viewBox="0 0 256 256"><path d="M216,24H40A16,16,0,0,0,24,40V216a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V40A16,16,0,0,0,216,24Zm0,192H40V40H216V216ZM96,112v64a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0Zm88,28v36a8,8,0,0,1-16,0V140a20,20,0,0,0-40,0v36a8,8,0,0,1-16,0V112a8,8,0,0,1,15.79-1.78A36,36,0,0,1,184,140ZM100,84A12,12,0,1,1,88,72,12,12,0,0,1,100,84Z"></path></svg>                </a>
            </div>
        </div>
    );
};

export default ShowMoreIcons;
