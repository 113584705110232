// EnterpriseMimosaSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseMimosaSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import B24 from "../../../assets/pdf/B24.pdf";
import B5x from "../../../assets/pdf/B5x.pdf";
import C5x from "../../../assets/pdf/C5x.pdf";
import C5C from "../../../assets/pdf/C5C.pdf";
import A5 from "../../../assets/pdf/A5.pdf";
import A5c from "../../../assets/pdf/A5c.pdf";
import A5x from "../../../assets/pdf/A5x.pdf";
import B11 from "../../../assets/pdf/B11.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";


const EnterpriseMimosaSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "PTP and PtMP - Mimosa",
  // };

  const productDataList1 = [
    //B11
    {
      images: [
        require("../../../assets/productImages/B11-1.png"),
        require("../../../assets/productImages/B11-2.png"),
        require("../../../assets/productImages/B11-1.png"),
        require("../../../assets/productImages/B11-2.png"),
      ],
      title: "Mimosa B11",
      about: (
        <>
          <p>Deliver aggregate speeds of up to 1.5 Gbps, with less than 1 ms of latency. Mimosa’s unique GPS Sync technology allows collocation and channel reuse.
          </p>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa B11 in  Rajasthan, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa B11.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Precision Engineered For Reliability.</li>
          <li>IP67 Out Of The Box.</li>
          <li>Bandwidth Changes Like Night & Day.</li>
        </>
      ),
      downloadLink: B11,
      hyperlinks: [],
    },

    //B24
    {
      images: [
        require("../../../assets/productImages/B24-1.png"),
        require("../../../assets/productImages/B24-1.png"),
        require("../../../assets/productImages/B24-1.png"),
        require("../../../assets/productImages/B24-1.png"),
      ],
      title: "Mimosa B24",
      about: (
        <>
          <p>Deliver aggregate speeds of up to 1.5 Gbps, with less than 1 ms of latency. Mimosa’s unique GPS Sync technology allows collocation and channel reuse.
          </p>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa B24 in  Rajasthan, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa B24.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Fixed wireless broadband backhaul.</li>
          <li>Business & MDU rooftop connections.</li>
          <li>Campus building connections.</li>
        </>
      ),
      downloadLink: B24,
      hyperlinks: [],
    },

    //B5x
    {
      images: [
        require("../../../assets/productImages/B5x-1.png"),
        require("../../../assets/productImages/B5x-1.png"),
        require("../../../assets/productImages/B5x-1.png"),
        require("../../../assets/productImages/B5x-1.png"),
      ],
      title: "Mimosa B5x",
      about: (
        <>
          <p>Deliver aggregate speeds of up to 1.5 Gbps, with less than 1 ms of latency. Mimosa’s unique GPS Sync technology allows collocation and channel reuse.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa B5x in  Rajasthan, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa B5x.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Broadband Service Provider.</li>
          <li>Public Safety Networks.</li>
          <li>Enterprise & Campus Connectivity.</li>
        </>
      ),
      downloadLink: B5x,
      hyperlinks: [],
    },

    //C5x
    {
      images: [
        require("../../../assets/productImages/C5x.png"),
        require("../../../assets/productImages/C5x.png"),
        require("../../../assets/productImages/C5x.png"),
        require("../../../assets/productImages/C5x.png"),
      ],
      title: "Mimosa C5X",
      about: (
        <>
          <p>Deliver aggregate speeds of up to 1 Gbps, with less than 1 ms of latency. Available in both integrated, modular, or connectorized antenna solutions.",
          </p>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa C5X in  Rajasthan, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa C5X.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Broadband Service Provider.</li>
          <li>Public Safety Networks.</li>
          <li>Multipoint Distribution for Security.</li>
        </>
      ),
      downloadLink: C5x,
      hyperlinks: [],
    },

    //C5C
    {
      images: [
        require("../../../assets/productImages/C5C-1.png"),
        require("../../../assets/productImages/C5C-1.png"),
        require("../../../assets/productImages/C5C-1.png"),
        require("../../../assets/productImages/C5C-1.png"),
      ],
      title: "Mimosa C5C",
      about: (
        <>
          <p>Deliver aggregate speeds of up to 1 Gbps, with less than 1 ms of latency. Available in both integrated, modular, or connectorized antenna solutions.,
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa C5C in  Rajasthan, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa C5C.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Long-range point-to-point (PTP) backhaul.</li>
          <li>Rural point-to-multipoint (PTMP) GigaPoP.</li>
          <li>Suburban MicroPoP when foliage exists.</li>
        </>
      ),
      downloadLink: C5C,
      hyperlinks: [],
    },

    //A5
    {
      images: [
        require("../../../assets/productImages/A5-1.png"),
        require("../../../assets/productImages/A5-1.png"),
        require("../../../assets/productImages/A5-1.png"),
        require("../../../assets/productImages/A5-1.png"),
      ],
      title: "Mimosa A5",
      about: (
        <>
          <p>They are the available base station and can work with any subscriber unit such as B series and C series to deliver aggregate speeds of up to 1 Gbps, with less than 1 ms of latency.
          </p>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa A5 in  Rajasthan, Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa A5.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Add Subscribers With Ease.</li>
          <li>Smart Quad-panel Mimo Technology.</li>
          <li>Fiber Speed Access At A Fraction Of The Cost.</li>
        </>
      ),
      downloadLink: A5,
      hyperlinks: [],
    },

    //A5c
    {
      images: [
        require("../../../assets/productImages/A5c-1.png"),
        require("../../../assets/productImages/A5c-1.png"),
        require("../../../assets/productImages/A5c-1.png"),
        require("../../../assets/productImages/A5c-1.png"),
      ],
      title: "Mimosa A5c",
      about: (
        <>
          <p>They are the available base station and can work with any subscriber unit such as B series and C series to deliver aggregate speeds of up to 1 Gbps, with less than 1 ms of latency.
          </p>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa A5c in  Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa A5c.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Increased Capacity At Each Site.</li>
          <li>Micropop Perfected.</li>
          <li>Network Scalability.</li>
        </>
      ),
      downloadLink: A5c,
      hyperlinks: [],
    },

    //A5x
    {
      images: [
        require("../../../assets/productImages/A5x-1.png"),
        require("../../../assets/productImages/A5x-1.png"),
        require("../../../assets/productImages/A5x-1.png"),
        require("../../../assets/productImages/A5x-1.png"),
      ],
      title: "Mimosa A5x",
      about: (
        <>
          <p>They are the available base station and can work with any subscriber unit such as B series and C series to deliver aggregate speeds of up to 1 Gbps, with less than 1 ms of latency.
          </p>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Mimosa A5x in  Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Mimosa A5x.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>PTMP at a Fraction of the Cost.</li>
          <li>It's Fast.</li>
          <li>Get Around the Noise.</li>
        </>
      ),
      downloadLink: A5x,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="mimosa-enterprise-page">
      <Helmet>
        <title>Mimosa Networks Products - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />
        {/* Dealer Tags */}
        <meta name="keywords" content="Mimosa Networks Products Dealer in Pune" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Pune" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Pune" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Mumbai" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Mumbai" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Mumbai" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Maharashtra" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Maharashtra" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Maharashtra" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Vadodara" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Vadodara" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Vadodara" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Surat" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Surat" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Surat" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Ahmedabad" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Indore" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Indore" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Indore" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Jaipur" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Jaipur" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Jaipur" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Noida" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Noida" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Noida" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Gurugram" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Gurugram" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Gurugram" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Delhi" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Delhi" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Delhi" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Kolkata" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Kolkata" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Kolkata" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Bhubaneswar" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Hyderabad" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Hyderabad" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Hyderabad" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Visakhapatnam" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Chennai" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Chennai" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Chennai" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Coimbatore" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Coimbatore" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Coimbatore" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Bengaluru" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Bengaluru" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Bengaluru" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Tamil Nadu" />

        <meta name="keywords" content="Mimosa Networks Products Dealer in Karnataka" />
        <meta name="keywords" content="Best Mimosa Networks Products Dealer in Karnataka" />
        <meta name="keywords" content="Mimosa Networks Products Distributor in Karnataka" />



        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseMimosaSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        PTP and PtMP UBR - Mimosa
      </div>
      <div className="mimosa-enterprise-subproduct-nav">
        <button
          className={`mimosa-enterprise-subproduct-nav-button ${currentSubproduct === 1 ? "active-mimosa-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          PTP and PtMP - Mimosa
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="mimosa-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="mimosa-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-mimosa-enterprise" : ""
                  }
                >
                  <button
                    className="mimosa-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <Whatsapp /> */}

      {/* <ContactButtons /> */}

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseMimosaSubcategoryPage;
