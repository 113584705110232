// ManufacturingPaloAltoSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingPaloAltoSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import PA220R from "../../../assets/pdf/PA-220R.pdf";
import PA450R from "../../../assets/pdf/PA-450R.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";

const ManufacturingPaloAltoSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Next Gen Firewall",
  // };

  const productDataList1 = [
    //PA-220R
    {
      images: [
        require("../../../assets/productImages/PA-220-1.png"),
        require("../../../assets/productImages/PA-220-2.png"),
        require("../../../assets/productImages/PA-220-1.png"),
        require("../../../assets/productImages/PA-220-2.png"),
      ],
      title: "PA-220R",
      about: (
        <>
          <p>
            The PA-220R ruggedized appliance secures industrial and defense
            networks in a rangeof harsh environments, such as utility
            substations, power plants, manufacturing plants, etc.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of PA-220R in Telangana, Taloja, Ahmedabad,
            Dewas, Ajmer, Noida, Konkan Belt, Belgaum. Contact us to avail best
            prices and discount for PA-220R.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>World's first ruggedized ML-Powered NGFW</li>
          <li>Fanless design with no moving parts</li>
        </>
      ),
      downloadLink: PA220R,
      hyperlinks: [],
    },

    //PA-450R
    {
      images: [
        require("../../../assets/productImages/PA-450R-1.jpg"),
        require("../../../assets/productImages/PA-450R-2.jpg"),
        require("../../../assets/productImages/PA-450R-3.jpg"),
        require("../../../assets/productImages/PA-450R-1.jpg"),
      ],
      title: "PA-450R",
      about: (
        <>
          <p>
            The PA-450R ruggedized appliance secures industrial and defense
            networks in a range of harsh environments, such as utility
            substations, power plants, manufacturing plants, etc.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor
            and service provider of PA-450R in Pune, Aurangabad, Nasik, Ahmednagar, Taloja, Shedung, Rabale, Kolhapur,Vapi, Halol, Vadodara, Bhavnagar, Surat,Bhilwara, Solan,Sambhalpur, Hosur, Belgaum. Contact us to avail best
            prices and discount for PA-450R.
          </p>
        </>
      ),
      manufactiringFeature: (
        <>
          <li>World's first ruggedized ML-Powered NGFW</li>
          <li>Features silent, fanless design with no moving parts</li>
        </>
      ),
      downloadLink: PA450R,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="paloAlto-page">
      <Helmet>
        <title>Palo Alto - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta
          name="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          name="keywords"
          content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation"
        />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Palo Alto Dealer in Pune" />
        <meta name="keywords" content="Best Palo Alto Dealer in Pune" />
        <meta name="keywords" content="Palo Alto Distributor in Pune" />

        <meta name="keywords" content="Palo Alto Dealer in Mumbai" />
        <meta name="keywords" content="Best Palo Alto Dealer in Mumbai" />
        <meta name="keywords" content="Palo Alto Distributor in Mumbai" />

        <meta name="keywords" content="Palo Alto Dealer in Maharashtra" />
        <meta name="keywords" content="Best Palo Alto Dealer in Maharashtra" />
        <meta name="keywords" content="Palo Alto Distributor in Maharashtra" />

        <meta name="keywords" content="Palo Alto Dealer in Vadodara" />
        <meta name="keywords" content="Best Palo Alto Dealer in Vadodara" />
        <meta name="keywords" content="Palo Alto Distributor in Vadodara" />

        <meta name="keywords" content="Palo Alto Dealer in Surat" />
        <meta name="keywords" content="Best Palo Alto Dealer in Surat" />
        <meta name="keywords" content="Palo Alto Distributor in Surat" />

        <meta name="keywords" content="Palo Alto Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Palo Alto Dealer in Ahmedabad" />
        <meta name="keywords" content="Palo Alto Distributor in Ahmedabad" />

        <meta name="keywords" content="Palo Alto Dealer in Indore" />
        <meta name="keywords" content="Best Palo Alto Dealer in Indore" />
        <meta name="keywords" content="Palo Alto Distributor in Indore" />

        <meta name="keywords" content="Palo Alto Dealer in Jaipur" />
        <meta name="keywords" content="Best Palo Alto Dealer in Jaipur" />
        <meta name="keywords" content="Palo Alto Distributor in Jaipur" />

        <meta name="keywords" content="Palo Alto Dealer in Noida" />
        <meta name="keywords" content="Best Palo Alto Dealer in Noida" />
        <meta name="keywords" content="Palo Alto Distributor in Noida" />

        <meta name="keywords" content="Palo Alto Dealer in Gurugram" />
        <meta name="keywords" content="Best Palo Alto Dealer in Gurugram" />
        <meta name="keywords" content="Palo Alto Distributor in Gurugram" />

        <meta name="keywords" content="Palo Alto Dealer in Delhi" />
        <meta name="keywords" content="Best Palo Alto Dealer in Delhi" />
        <meta name="keywords" content="Palo Alto Distributor in Delhi" />

        <meta name="keywords" content="Palo Alto Dealer in Kolkata" />
        <meta name="keywords" content="Best Palo Alto Dealer in Kolkata" />
        <meta name="keywords" content="Palo Alto Distributor in Kolkata" />

        <meta name="keywords" content="Palo Alto Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Palo Alto Dealer in Bhubaneswar" />
        <meta name="keywords" content="Palo Alto Distributor in Bhubaneswar" />

        <meta name="keywords" content="Palo Alto Dealer in Hyderabad" />
        <meta name="keywords" content="Best Palo Alto Dealer in Hyderabad" />
        <meta name="keywords" content="Palo Alto Distributor in Hyderabad" />

        <meta name="keywords" content="Palo Alto Dealer in Visakhapatnam" />
        <meta
          name="keywords"
          content="Best Palo Alto Dealer in Visakhapatnam"
        />
        <meta
          name="keywords"
          content="Palo Alto Distributor in Visakhapatnam"
        />

        <meta name="keywords" content="Palo Alto Dealer in Chennai" />
        <meta name="keywords" content="Best Palo Alto Dealer in Chennai" />
        <meta name="keywords" content="Palo Alto Distributor in Chennai" />

        <meta name="keywords" content="Palo Alto Dealer in Coimbatore" />
        <meta name="keywords" content="Best Palo Alto Dealer in Coimbatore" />
        <meta name="keywords" content="Palo Alto Distributor in Coimbatore" />

        <meta name="keywords" content="Palo Alto Dealer in Bengaluru" />
        <meta name="keywords" content="Best Palo Alto Dealer in Bengaluru" />
        <meta name="keywords" content="Palo Alto Distributor in Bengaluru" />

        <meta name="keywords" content="Palo Alto Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Palo Alto Dealer in Tamil Nadu" />
        <meta name="keywords" content="Palo Alto Distributor in Tamil Nadu" />

        <meta name="keywords" content="Palo Alto Dealer in Karnataka" />
        <meta name="keywords" content="Best Palo Alto Dealer in Karnataka" />
        <meta name="keywords" content="Palo Alto Distributor in Karnataka" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta
          property="og:description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          property="og:image"
          content="https://calidtech.com/og-image.jpg"
        />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta
          name="twitter:description"
          content="Discover how Calid Technologies delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring."
        />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />
        <meta itemprop="name" content="Calid Technologies" />
        <meta
          itemprop="description"
          content="Calid Technologies offers innovative enterprise solutions and factory digital transformations."
        />
        <meta
          itemprop="image"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png"
        />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="canonical"
          href="https://calidtech.com/ManufacturingPaloAltoSubcategoryPage"
        />
      </Helmet>

      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        OT Security - Palo Alto
      </div>
      <div className="paloAlto-subproduct-nav">
        <button
          className={`paloAlto-subproduct-nav-button ${currentSubproduct === 1 ? "active-paloAlto-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Next Gen Firewall
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="paloAlto-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-paloAlto-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-paloAlto" : ""}
                >
                  <button
                    className="paloAlto-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <Whatsapp /> */}

      <SwitchtoEnterprise />
      {/* <ContactButtons /> */}

      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingPaloAltoSubcategoryPage;
