// EnterpriseRadwinSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseRadwinSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import Radwin2000E from "../../../assets/pdf/Radwin-2000E.pdf";
import RADWINJETAIR from "../../../assets/pdf/RADWIN-JET-AIR.pdf";
import MultiSectorPtMP from "../../../assets/pdf/MultiSector-PtMP.pdf";
import SubscriberUnit from "../../../assets/pdf/Subscriber-Unit.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import { Helmet } from 'react-helmet';
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";


const EnterpriseRadwinSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "PTP and PtMP - Radwin",
  // };

  const productDataList1 = [
    //Radwin 2000E
    {
      images: [
        require("../../../assets/productImages/Radwin-2000E.png"),
        require("../../../assets/productImages/Radwin-2000E.png"),
        require("../../../assets/productImages/Radwin-2000E.png"),
        require("../../../assets/productImages/Radwin-2000E.png"),
      ],
      title: "Radwin 2000E",
      about: (
        <>
          <p>RADWIN 2000 E is a Point-to-Point outdoor radio that delivers up to 2.5Gbps and is built for ultra-high capacity applications such as backhaul and mission-critical data connectivity.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Radwin 2000E in  Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Radwin 2000E.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Up to 2.5Gbps @ 160MHz, 1.2Gbps @ 80MHz.</li>
          <li>4096 QAM enabling 15.6 bps/Hz.</li>
          <li>Dynamic channel bandwidth: 20-40-80-160MHz.</li>
        </>
      ),
      downloadLink: Radwin2000E,
      hyperlinks: [],
    },

    //RADWIN JET AIR
    {
      images: [
        require("../../../assets/productImages/RADWIN-JET-AIR.png"),
        require("../../../assets/productImages/RADWIN-JET-AIR.png"),
        require("../../../assets/productImages/RADWIN-JET-AIR.png"),
        require("../../../assets/productImages/RADWIN-JET-AIR.png"),
      ],
      title: "RADWIN JET AIR",
      about: (
        <>
          <p>Point-to-MultiPoint base station that boasts a superior beamforming antenna for best interference immunity and reliable connectivity, even in the heavily congested unlicensed spectrum.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of RADWIN JET AIR in  Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Maharashtra, Chennai, Pune. Contact us to avail best prices and discount  for RADWIN JET AIR.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Superior beamforming antenna.</li>
          <li>Up to 750Mbps, 3Gbps per 4 sectors.</li>
          <li>Sector width: 90°.</li>
        </>
      ),
      downloadLink: RADWINJETAIR,
      hyperlinks: [],
    },

    //MultiSector PtMP base station
    {
      images: [
        require("../../../assets/productImages/MultiSector-PtMP-1.png"),
        require("../../../assets/productImages/MultiSector-PtMP-1.png"),
        require("../../../assets/productImages/MultiSector-PtMP-1.png"),
        require("../../../assets/productImages/MultiSector-PtMP-1.png"),
      ],
      title: "Radwin MultiSector PtMP",
      about: (
        <>
          <p>Dual-carrier (radio) self-contained base station series, supporting up to 4 sectors for MicroPoP and long-range network deployments in sparse rural areas.Up to 360 ̊ coverage.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Radwin MultiSector PtMP in  Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Radwin MultiSector PtMP.</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Up to 360˚ coverage.</li>
          <li>High quality for constrained budgets.</li>
          <li>Reduced site complexity.</li>
        </>
      ),
      downloadLink: MultiSectorPtMP,
      hyperlinks: [],
    },

    //Subscriber Unit
    {
      images: [
        require("../../../assets/productImages/Subscriber-Unit-1.png"),
        require("../../../assets/productImages/Subscriber-Unit-2.png"),
        require("../../../assets/productImages/Subscriber-Unit-1.png"),
        require("../../../assets/productImages/Subscriber-Unit-2.png"),
      ],
      title: "Radwin Subscriber Unit",
      about: (
        <>
          <p>Radwin Subscriber unit available with multiple combination and works with Radwin JET, Neo and Multisector base station. SU AIR is interoperable with all RADWIN 5GHz base stations.
          </p>
          <br></br>
          <p>
            Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Radwin Subscriber Unit in  Rajasthan, Telangana, Gujarat, Bangalore, Orissa, Goa, Noida, Rohtak, Gurgaon, Mumbai, Chennai, Pune. Contact us to avail best prices and discount  for Radwin Subscriber Unit.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Automated installation via mobile App.</li>
          <li>22dBi Integrated antenna.</li>
          <li>Up to 500Mbps Subscriber Unit.</li>
        </>
      ),
      downloadLink: SubscriberUnit,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="radwin-enterprise-page">
      <Helmet>
        <title>Radwin Products - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />
        {/* Dealer Tags */}
        <meta name="keywords" content="Radwin Products Dealer in Pune" />
        <meta name="keywords" content="Best Radwin Products Dealer in Pune" />
        <meta name="keywords" content="Radwin Products Distributor in Pune" />

        <meta name="keywords" content="Radwin Products Dealer in Mumbai" />
        <meta name="keywords" content="Best Radwin Products Dealer in Mumbai" />
        <meta name="keywords" content="Radwin Products Distributor in Mumbai" />

        <meta name="keywords" content="Radwin Products Dealer in Maharashtra" />
        <meta name="keywords" content="Best Radwin Products Dealer in Maharashtra" />
        <meta name="keywords" content="Radwin Products Distributor in Maharashtra" />

        <meta name="keywords" content="Radwin Products Dealer in Vadodara" />
        <meta name="keywords" content="Best Radwin Products Dealer in Vadodara" />
        <meta name="keywords" content="Radwin Products Distributor in Vadodara" />

        <meta name="keywords" content="Radwin Products Dealer in Surat" />
        <meta name="keywords" content="Best Radwin Products Dealer in Surat" />
        <meta name="keywords" content="Radwin Products Distributor in Surat" />

        <meta name="keywords" content="Radwin Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Radwin Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Radwin Products Distributor in Ahmedabad" />

        <meta name="keywords" content="Radwin Products Dealer in Indore" />
        <meta name="keywords" content="Best Radwin Products Dealer in Indore" />
        <meta name="keywords" content="Radwin Products Distributor in Indore" />

        <meta name="keywords" content="Radwin Products Dealer in Jaipur" />
        <meta name="keywords" content="Best Radwin Products Dealer in Jaipur" />
        <meta name="keywords" content="Radwin Products Distributor in Jaipur" />

        <meta name="keywords" content="Radwin Products Dealer in Noida" />
        <meta name="keywords" content="Best Radwin Products Dealer in Noida" />
        <meta name="keywords" content="Radwin Products Distributor in Noida" />

        <meta name="keywords" content="Radwin Products Dealer in Gurugram" />
        <meta name="keywords" content="Best Radwin Products Dealer in Gurugram" />
        <meta name="keywords" content="Radwin Products Distributor in Gurugram" />

        <meta name="keywords" content="Radwin Products Dealer in Delhi" />
        <meta name="keywords" content="Best Radwin Products Dealer in Delhi" />
        <meta name="keywords" content="Radwin Products Distributor in Delhi" />

        <meta name="keywords" content="Radwin Products Dealer in Kolkata" />
        <meta name="keywords" content="Best Radwin Products Dealer in Kolkata" />
        <meta name="keywords" content="Radwin Products Distributor in Kolkata" />

        <meta name="keywords" content="Radwin Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Radwin Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Radwin Products Distributor in Bhubaneswar" />

        <meta name="keywords" content="Radwin Products Dealer in Hyderabad" />
        <meta name="keywords" content="Best Radwin Products Dealer in Hyderabad" />
        <meta name="keywords" content="Radwin Products Distributor in Hyderabad" />

        <meta name="keywords" content="Radwin Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Radwin Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Radwin Products Distributor in Visakhapatnam" />

        <meta name="keywords" content="Radwin Products Dealer in Chennai" />
        <meta name="keywords" content="Best Radwin Products Dealer in Chennai" />
        <meta name="keywords" content="Radwin Products Distributor in Chennai" />

        <meta name="keywords" content="Radwin Products Dealer in Coimbatore" />
        <meta name="keywords" content="Best Radwin Products Dealer in Coimbatore" />
        <meta name="keywords" content="Radwin Products Distributor in Coimbatore" />

        <meta name="keywords" content="Radwin Products Dealer in Bengaluru" />
        <meta name="keywords" content="Best Radwin Products Dealer in Bengaluru" />
        <meta name="keywords" content="Radwin Products Distributor in Bengaluru" />

        <meta name="keywords" content="Radwin Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Radwin Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Radwin Products Distributor in Tamil Nadu" />

        <meta name="keywords" content="Radwin Products Dealer in Karnataka" />
        <meta name="keywords" content="Best Radwin Products Dealer in Karnataka" />
        <meta name="keywords" content="Radwin Products Distributor in Karnataka" />


        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseRadwinSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        PTP and PtMP UBR - Radwin
      </div>
      <div className="radwin-enterprise-subproduct-nav">
        <button
          className={`radwin-enterprise-subproduct-nav-button ${currentSubproduct === 1 ? "active-radwin-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          PTP and PtMP - Radwin
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="radwin-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="radwin-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-radwin-enterprise" : ""
                  }
                >
                  <button
                    className="radwin-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <Whatsapp /> */}

      {/* <ContactButtons /> */}

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseRadwinSubcategoryPage;
