import React from "react";
import Featurebox from "../ManufacturingComponents/Featurebox";
import fimage1 from "../../assets/images/IoT.jpg";
import fimage2 from "../../assets/images/IndustrialCommunicationAndCmputing.png";
import fimage3 from "../../assets/images/DataAnalytics.png";
import fimage4 from "../../assets/images/OTSecurity.png";
import "./Featurebox.css";

function Feature() {
  return (
    <div id="features">
      <h1>
        <div className="main-heading-entire-site">Factory Digital Transformation Categories</div>
      </h1>
      <div className="a-container">
        <Featurebox
          image={fimage1}
          title="Industrial Edge Connectivity"
          featureLink="/ManufacturingIndustrialEdgeConnectivityCategoryPage"
        />
        
        
        <Featurebox
          image={fimage2}
          title="Industrial communication and computing"
          featureLink="/ManufacturingIndustrialCommunicationAndComputingCategoryPage"
        />
        <Featurebox
          image={fimage3}
          title="Analytics"
          featureLink="/ManufacturingAnalyticsCategoryPage"
        />
        <Featurebox
          image={fimage4}
          title="OT Security"
          featureLink="/ManufacturingOTSecurityCategoryPage"
        />
      </div>
    </div>
  );
}

export default Feature;
