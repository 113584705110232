// EnterpriseNetworkAndCybersecurityCategoryPage.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EnterpriseNetworkAndCybersecurityCategoryPage.css";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import heroprod from "../../../assets/images/smallmediumslider.jpeg";
import heroprod1 from "../../../assets/images/smalmediumslider1.jpg";
import routersImage from "../../../assets/images/switching.jpg";
import switchesImage from "../../../assets/images/wirelesscate1.jpg";
import accessPointsImage from "../../../assets/images/sophos.png";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import EnterpriseSubCategory from "../../EntertrpriseComponents/EnterpriseSubCategory";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";

const EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const subcategories = [
    {
      name: "Switching",
      image: routersImage,
      path: "/EnterpriseSwitchingSubcategoryPage",
      description: "",
    },
    {
      name: "Wireless",
      image: switchesImage,
      path: "/EnterpriseWirelessSubcategoryPage",
      description: "",
    },
  ];

  return (
    <div className="enterprise-category">
      <Helmet>
        <title>Small Medium Business LAN and Wireless - Dlink, Edgecore, Aruba, Ruijie, Quantum and Aruba Products Dealer, Distributor and supplier in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Discover tailored Switching and Wireless solutions for small and medium businesses. Enhance your business connectivity with advanced networking products and expert services from Calid Technologies." />
        <meta name="keywords" content="Small Business IT Infrastructure,Business Wireless Solutions,LAN/WAN Solutions for SMB,SMB Switching Solutions,Small Business Wi-Fi Solutions,SMB Network Infrastructure,Wireless LAN for Small Business,Enterprise-Grade LAN for SMB,Business-Grade Networking for SMB,SMB LAN Solutions,Small Business Wireless Networks,Small Business Network Solutions,LAN Solutions for Small Business,SMB lan and wireless supplier in mumbai ,pune ll india" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="SMB Lan and Wireless" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="CALID TECHNOLOGY" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="SMB Lan and Wireless" />
        <meta name="twitter:description" content="Discover tailored Switching and Wireless solutions for small and medium businesses. Enhance your business connectivity with advanced networking products and expert services from Calid Technologies." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="SMB Lan and Wireless" />
        <meta itemprop="description" content="Discover tailored Switching and Wireless solutions for small and medium businesses. Enhance your business connectivity with advanced networking products and expert services from Calid Technologies." />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Small Medium Business LAN & Wireless
      </div>
      <div className="container-enterprise mt-5">
        <Slider {...settings}>
          <div>
            <img
              src={heroprod}
              alt="switching"
              style={{ width: "100%", display: "block" }}
            />
          </div>
          <div>
            <img
              src={heroprod1}
              alt="Slide 2"
              style={{ width: "100%", display: "block" }}
            />
          </div>
        </Slider>
        <br></br>
        <br></br>
        <h1 className="enterprise-category-heading">
          <div class="main-heading-entire-site">
            Small Medium Business LAN & Wireless
          </div>
        </h1>
        <p className="enterprise-category-information">
          Welcome to our Small Medium Business (SMB) LAN & Wireless solutions. As an authorized dealer and supplier throughout India, including key cities like Mumbai, Pune, Delhi, Bengaluru, Hyderabad, and Chennai, we specialize in delivering premium networking products designed to boost business operations. We understand the unique requirements of SMBs and provide customized solutions to ensure seamless connectivity and efficient network management. Discover our comprehensive range of products in two main categories: Switching and Wireless, crafted to support your business’s growth and connectivity needs.
        </p>
        <h2 className="enterprise-subcategory-heading">Subcategories</h2>
        <EnterpriseSubCategory subcategories={subcategories} />
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <ContactButtons /> */}

      {/* <Whatsapp /> */}

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseSmallMediumBusinessLANAndWirelessCategoryPage;
