// EnterpriseDellSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseDellSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import PowerEdgeT150 from "../../../assets/pdf/PowerEdge-T150.pdf";
import PowerEdgeR650XS from "../../../assets/pdf/PowerEdge-R650XS.pdf";
import PowerEdgeR750XS from "../../../assets/pdf/PowerEdge-R750XS.pdf";
import PowerEdgeT440 from "../../../assets/pdf/PowerEdge-T440.pdf";
import PowerStore500T from "../../../assets/pdf/PowerStore-500T.pdf";
import PowerStore1200T from "../../../assets/pdf/PowerStore-1200T.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';
import ContactButtons from "../../../Common Components/ContactButtons";
import CallMePopup from "../../../Common Components/CallMePopup";
import ShowMoreIcons from "../../../Common Components/ShowMoreIcons";


const EnterpriseDellSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  const productDataList1 = [
    //PowerEdge T150
    {
      images: [
        require("../../../assets/productImages/PowerEdge-T150-1.png"),
        require("../../../assets/productImages/PowerEdge-T150-2.png"),
        require("../../../assets/productImages/PowerEdge-T150-3.png"),
        require("../../../assets/productImages/PowerEdge-T150-4.png"),
      ],
      title: "Dell PowerEdge T150",
      about: (
        <>
          <p>The Dell PowerEdge T150, powered by the Intel® Xeon® E-2300 processors, is an entry level tower server with an ideal balance of adaptability and affordability.
          </p>
          <br></br>
          <p>Calid Technologies is authorized partner, reseller, Supplier,vendor and Service provider of Dell PowerEdge T150 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Maharashtra. Contact us to avail best prices and discount for Dell PowerEdge T150</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Ideal for file/print, mail/messaging, point of sale, web hosting and
            collaboration.
          </li>
          <li>Offers enhanced thermal efficiency.</li>
        </>
      ),
      downloadLink: PowerEdgeT150,
      hyperlinks: [
        // { link: "https://example.com/case-study-1", name: "Case Study 1" },
      ],
    },

    //PowerEdge T440
    {
      images: [
        require("../../../assets/productImages/PowerEdge-T440.png"),
        require("../../../assets/productImages/PowerEdge-T440.png"),
        require("../../../assets/productImages/PowerEdge-T440.png"),
        require("../../../assets/productImages/PowerEdge-T440.png"),
      ],
      title: "Dell PowerEdge T440",
      about: (
        <>
          <p>Dell PowerEdge T440 tower server delivers powerful 2-socket performance, expandability and quiet operation to your small-to mid-sized business (SMB) and remote office/branch office (ROBO) sites.</p>
          <br></br>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Dell PowerEdge T440 in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for Dell PowerEdge T440"</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Drive application response times faster with 33% more capacity for
            data-in-memory.
          </li>
          <li>Rely on PowerEdge with integrated security.</li>
        </>
      ),
      downloadLink: PowerEdgeT440,
      hyperlinks: [],
    },

    //PowerEdge R650XS
    {
      images: [
        require("../../../assets/productImages/PowerEdge-R650XS-1.png"),
        require("../../../assets/productImages/PowerEdge-R650XS-2.png"),
        require("../../../assets/productImages/PowerEdge-R650XS-3.png"),
        require("../../../assets/productImages/PowerEdge-R650XS-4.png"),
      ],
      title: "Dell PowerEdge R650XS",
      about: (
        <>
          <p>
            The Dell EMC PowerEdge R650xs, with 3rd generation Intel® Xeon® Scalable processors, designed to meet a specific set of requirements for medium-duty workloads with enterprise-class performance.
          </p>
          <br></br>
          <p>Calid Technologies is authorized partner, reseller, Supplier,vendor and Service provider of Dell PowerEdge R650XS in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon, Maharashtra. Contact us to avail best prices and discount for Dell PowerEdge R650XS</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Full-stack management integration with Microsoft, VMware,
            ServiceNow, Ansible and many other tools for multiple operating
            environments, from on-premises to cloud to edge.
          </li>
        </>
      ),
      downloadLink: PowerEdgeR650XS,
      hyperlinks: [],
    },

    //PowerEdge R750XS
    {
      images: [
        require("../../../assets/productImages/PowerEdge-R750XS-1.png"),
        require("../../../assets/productImages/PowerEdge-R750XS-2.png"),
        require("../../../assets/productImages/PowerEdge-R750XS-3.png"),
        require("../../../assets/productImages/PowerEdge-R750XS-4.png"),
      ],
      title: "Dell PowerEdge R750XS",
      about: (
        <>
          <p>The Dell EMC PowerEdge R750xs, with 3rd generation Intel® Xeon® Scalable processors, is purposefully designed with distinctive, , customer-defined requirements.
          </p>
          <br></br>
          <p>Calid Technologies is authorized partner, reseller, Supplier,vendor and Service provider of Dell PowerEdge R750XS in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for Dell PowerEdge R750XS.</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Full-stack management integration with Microsoft, VMware,
            ServiceNow, Ansible and many other tools for on-premises, edge and
            cloud environments.
          </li>
        </>
      ),
      downloadLink: PowerEdgeR750XS,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //powerstore 500T
    {
      images: [
        require("../../../assets/productImages/PowerStore-500T.png"),
        require("../../../assets/productImages/PowerStore-500T.png"),
        require("../../../assets/productImages/PowerStore-500T.png"),
        require("../../../assets/productImages/PowerStore-500T.png"),
      ],
      title: "Dell PowerStore 500T",
      about: (
        <>
          <p>The ground-breaking Dell PowerStore enterprise storage appliance helps you achieve new levels of operational agility with advanced storage technologies.
          </p>
          <br></br>
          <p>Calid Technologies is authorized partner, reseller, Supplier,vendor and Service provider of Dell PowerStore 500T in Gujarat, Orisa, Bangalore, Goa, Noida, Rohtak, Gurgaon, Maharashtra. Contact us to avail best prices and discount for Dell PowerStore 500T</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Connectivity.</li>
          <li>Back-end (Drive) connectivity.</li>
          <li>OE protocols and software facilities.</li>
        </>
      ),
      downloadLink: PowerStore500T,
      hyperlinks: [],
    },

    //powerstore 1200T
    {
      images: [
        require("../../../assets/productImages/PowerStore-1200T.png"),
        require("../../../assets/productImages/PowerStore-1200T.png"),
        require("../../../assets/productImages/PowerStore-1200T.png"),
        require("../../../assets/productImages/PowerStore-1200T.png"),
      ],
      title: "Dell PowerStore 1200T",
      about: (
        <>
          <p>The ground-breaking Dell PowerStore enterprise storage appliance helps you achieve new levels of operational agility  with advanced storage technologies.
          </p>
          <br></br>
          <p>Calid Technologies is authorized partner, reseller, Supplier,vendor and Service provider of Dell PowerStore 1200T in Pune, Mumbai,Vadodara,Surat, Ahmedabad, Indore, Jaipur, Gurugram, kolkata, Bhubaneswar, Hyderabad, Vishakhapatnam, Coimbatore, Chennai, Bengaluru. Contact us to avail best prices and discount for Dell PowerStore 1200T</p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>IPv6 certification .</li>
          <li>Native SHA2 certificate .</li>
          <li>Restriction of Hazardous Substances (RoHS) compliance.</li>
        </>
      ),
      downloadLink: PowerStore1200T,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="dell-enterprise-page">
      <Helmet>
        <title>Dell Server and Storage - Dealer Distributor in Pune, Mumbai, Maharashtra, Vadodara, Surat, Ahmedabad, Gujarat, Indore, MP, Jaipur, Rajasthan, Noida, Gurugram, Delhi, Kolkata, West Bengal, Bhubaneswar, Orissa, Hyderabad, Telangana, Vishakhapatnam, AP, Chennai, Coimbatore, TN, Bengaluru, Karnataka, India</title>
        <meta name="description" content="Your website description here" />

        {/* Dealer Tags */}

        <meta name="keywords" content="Dell Server Dealer in Pune" />
        <meta name="keywords" content="Best Dell Server Dealer in Pune" />
        <meta name="keywords" content="Dell Server Distributor in Pune" />

        <meta name="keywords" content="Dell Server Dealer in Mumbai" />
        <meta name="keywords" content="Best Dell Server Dealer in Mumbai" />
        <meta name="keywords" content="Dell Server Distributor in Mumbai" />

        <meta name="keywords" content="Dell Server Dealer in Maharashtra" />
        <meta name="keywords" content="Best Dell Server Dealer in Maharashtra" />
        <meta name="keywords" content="Dell Server Distributor in Maharashtra" />

        <meta name="keywords" content="Dell Server Dealer in Vadodara" />
        <meta name="keywords" content="Best Dell Server Dealer in Vadodara" />
        <meta name="keywords" content="Dell Server Distributor in Vadodara" />

        <meta name="keywords" content="Dell Server Dealer in Surat" />
        <meta name="keywords" content="Best Dell Server Dealer in Surat" />
        <meta name="keywords" content="Dell Server Distributor in Surat" />

        <meta name="keywords" content="Dell Server Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Dell Server Dealer in Ahmedabad" />
        <meta name="keywords" content="Dell Server Distributor in Ahmedabad" />

        <meta name="keywords" content="Dell Server Dealer in Gujarat" />
        <meta name="keywords" content="Best Dell Server Dealer in Gujarat" />
        <meta name="keywords" content="Dell Server Distributor in Gujarat" />

        <meta name="keywords" content="Dell Server Dealer in Indore" />
        <meta name="keywords" content="Best Dell Server Dealer in Indore" />
        <meta name="keywords" content="Dell Server Distributor in Indore" />

        <meta name="keywords" content="Dell Server Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best Dell Server Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Dell Server Distributor in Madhya Pradesh" />

        <meta name="keywords" content="Dell Server Dealer in Jaipur" />
        <meta name="keywords" content="Best Dell Server Dealer in Jaipur" />
        <meta name="keywords" content="Dell Server Distributor in Jaipur" />

        <meta name="keywords" content="Dell Server Dealer in Rajasthan" />
        <meta name="keywords" content="Best Dell Server Dealer in Rajasthan" />
        <meta name="keywords" content="Dell Server Distributor in Rajasthan" />

        <meta name="keywords" content="Dell Server Dealer in Noida" />
        <meta name="keywords" content="Best Dell Server Dealer in Noida" />
        <meta name="keywords" content="Dell Server Distributor in Noida" />

        <meta name="keywords" content="Dell Server Dealer in Gurugram" />
        <meta name="keywords" content="Best Dell Server Dealer in Gurugram" />
        <meta name="keywords" content="Dell Server Distributor in Gurugram" />

        <meta name="keywords" content="Dell Server Dealer in Delhi" />
        <meta name="keywords" content="Best Dell Server Dealer in Delhi" />
        <meta name="keywords" content="Dell Server Distributor in Delhi" />

        <meta name="keywords" content="Dell Server Dealer in Kolkata" />
        <meta name="keywords" content="Best Dell Server Dealer in Kolkata" />
        <meta name="keywords" content="Dell Server Distributor in Kolkata" />

        <meta name="keywords" content="Dell Server Dealer in West Bengal" />
        <meta name="keywords" content="Best Dell Server Dealer in West Bengal" />
        <meta name="keywords" content="Dell Server Distributor in West Bengal" />

        <meta name="keywords" content="Dell Server Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Dell Server Dealer in Bhubaneswar" />
        <meta name="keywords" content="Dell Server Distributor in Bhubaneswar" />

        <meta name="keywords" content="Dell Server Dealer in Odisha" />
        <meta name="keywords" content="Best Dell Server Dealer in Odisha" />
        <meta name="keywords" content="Dell Server Distributor in Odisha" />

        <meta name="keywords" content="Dell Server Dealer in Hyderabad" />
        <meta name="keywords" content="Best Dell Server Dealer in Hyderabad" />
        <meta name="keywords" content="Dell Server Distributor in Hyderabad" />

        <meta name="keywords" content="Dell Server Dealer in Telangana" />
        <meta name="keywords" content="Best Dell Server Dealer in Telangana" />
        <meta name="keywords" content="Dell Server Distributor in Telangana" />

        <meta name="keywords" content="Dell Server Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Dell Server Dealer in Visakhapatnam" />
        <meta name="keywords" content="Dell Server Distributor in Visakhapatnam" />

        <meta name="keywords" content="Dell Server Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best Dell Server Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Dell Server Distributor in Andhra Pradesh" />

        <meta name="keywords" content="Dell Server Dealer in Chennai" />
        <meta name="keywords" content="Best Dell Server Dealer in Chennai" />
        <meta name="keywords" content="Dell Server Distributor in Chennai" />

        <meta name="keywords" content="Dell Server Dealer in Coimbatore" />
        <meta name="keywords" content="Best Dell Server Dealer in Coimbatore" />
        <meta name="keywords" content="Dell Server Distributor in Coimbatore" />

        <meta name="keywords" content="Dell Server Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Dell Server Dealer in Tamil Nadu" />
        <meta name="keywords" content="Dell Server Distributor in Tamil Nadu" />

        <meta name="keywords" content="Dell Server Dealer in Bengaluru" />
        <meta name="keywords" content="Best Dell Server Dealer in Bengaluru" />
        <meta name="keywords" content="Dell Server Distributor in Bengaluru" />

        <meta name="keywords" content="Dell Server Dealer in Karnataka" />
        <meta name="keywords" content="Best Dell Server Dealer in Karnataka" />
        <meta name="keywords" content="Dell Server Distributor in Karnataka" />

        <meta name="keywords" content="Dell Storage Dealer in Pune" />
        <meta name="keywords" content="Best Dell Storage Dealer in Pune" />
        <meta name="keywords" content="Dell Storage Distributor in Pune" />

        <meta name="keywords" content="Dell Storage Dealer in Mumbai" />
        <meta name="keywords" content="Best Dell Storage Dealer in Mumbai" />
        <meta name="keywords" content="Dell Storage Distributor in Mumbai" />

        <meta name="keywords" content="Dell Storage Dealer in Maharashtra" />
        <meta name="keywords" content="Best Dell Storage Dealer in Maharashtra" />
        <meta name="keywords" content="Dell Storage Distributor in Maharashtra" />

        <meta name="keywords" content="Dell Storage Dealer in Vadodara" />
        <meta name="keywords" content="Best Dell Storage Dealer in Vadodara" />
        <meta name="keywords" content="Dell Storage Distributor in Vadodara" />

        <meta name="keywords" content="Dell Storage Dealer in Surat" />
        <meta name="keywords" content="Best Dell Storage Dealer in Surat" />
        <meta name="keywords" content="Dell Storage Distributor in Surat" />

        <meta name="keywords" content="Dell Storage Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Dell Storage Dealer in Ahmedabad" />
        <meta name="keywords" content="Dell Storage Distributor in Ahmedabad" />

        <meta name="keywords" content="Dell Storage Dealer in Gujarat" />
        <meta name="keywords" content="Best Dell Storage Dealer in Gujarat" />
        <meta name="keywords" content="Dell Storage Distributor in Gujarat" />

        <meta name="keywords" content="Dell Storage Dealer in Indore" />
        <meta name="keywords" content="Best Dell Storage Dealer in Indore" />
        <meta name="keywords" content="Dell Storage Distributor in Indore" />

        <meta name="keywords" content="Dell Storage Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best Dell Storage Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Dell Storage Distributor in Madhya Pradesh" />

        <meta name="keywords" content="Dell Storage Dealer in Jaipur" />
        <meta name="keywords" content="Best Dell Storage Dealer in Jaipur" />
        <meta name="keywords" content="Dell Storage Distributor in Jaipur" />

        <meta name="keywords" content="Dell Storage Dealer in Rajasthan" />
        <meta name="keywords" content="Best Dell Storage Dealer in Rajasthan" />
        <meta name="keywords" content="Dell Storage Distributor in Rajasthan" />

        <meta name="keywords" content="Dell Storage Dealer in Noida" />
        <meta name="keywords" content="Best Dell Storage Dealer in Noida" />
        <meta name="keywords" content="Dell Storage Distributor in Noida" />

        <meta name="keywords" content="Dell Storage Dealer in Gurugram" />
        <meta name="keywords" content="Best Dell Storage Dealer in Gurugram" />
        <meta name="keywords" content="Dell Storage Distributor in Gurugram" />

        <meta name="keywords" content="Dell Storage Dealer in Delhi" />
        <meta name="keywords" content="Best Dell Storage Dealer in Delhi" />
        <meta name="keywords" content="Dell Storage Distributor in Delhi" />

        <meta name="keywords" content="Dell Storage Dealer in Kolkata" />
        <meta name="keywords" content="Best Dell Storage Dealer in Kolkata" />
        <meta name="keywords" content="Dell Storage Distributor in Kolkata" />

        <meta name="keywords" content="Dell Storage Dealer in West Bengal" />
        <meta name="keywords" content="Best Dell Storage Dealer in West Bengal" />
        <meta name="keywords" content="Dell Storage Distributor in West Bengal" />

        <meta name="keywords" content="Dell Storage Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Dell Storage Dealer in Bhubaneswar" />
        <meta name="keywords" content="Dell Storage Distributor in Bhubaneswar" />

        <meta name="keywords" content="Dell Storage Dealer in Odisha" />
        <meta name="keywords" content="Best Dell Storage Dealer in Odisha" />



        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseDellSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        Server and Storage Solution - Dell
      </div>
      <div className="dell-enterprise-subproduct-nav">
        <button
          className={`dell-enterprise-subproduct-nav-button ${currentSubproduct === 1 ? "active-dell-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Tower and Rackmount Servers
        </button>
        <button
          className={`dell-enterprise-subproduct-nav-button ${currentSubproduct === 2 ? "active-dell-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Storage
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="dell-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="dell-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-dell-enterprise" : ""
                  }
                >
                  <button
                    className="dell-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      {/* <ContactUsBot /> */}
      <CallMePopup />
      <ShowMoreIcons />
      {/* <Whatsapp /> */}

      {/* <ContactButtons /> */}

      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseDellSubcategoryPage;
